$font-family: 'spartan', sans-serif;

@mixin font-regular {
  font-weight: 400;
  font-family: $font-family;
  font-style: normal;
  text-decoration: none
}

@mixin font-bold {
  font-weight: 800;
  font-family: $font-family;
  font-style: normal;
  text-decoration: none
}

@mixin font-light {
  font-weight: 100;
  font-family: $font-family;
  font-style: normal;
  text-decoration: none
}
