@import "src/scss/config/config";

.swiper-wrapper {
  margin-left: 0;

  @media (min-width: $breakpoint--md) {
    margin-left: 15px;
  }
}

.swiper {
  &-button {
    &-next, &-prev {
      color: $lightest;
      @media (max-width: $breakpoint--md) {
        color: $base-warm-light;
      }
    }

  }
}

.projecten_img {
  margin-top: 50px;
  img {
    height: 200px;
    width: auto;
  }

  .swiper {
    &-button {
      &-next, &-prev {
        color: $base-warm-light;
      }

    }
  }
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
