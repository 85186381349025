@import "src/scss/config/config";

.subtitle {
  box-sizing: border-box;

  &__line {
    &_sm {
      margin-left: 5%;
      height: 15px;
      width: 250px;
      @media (min-width: $breakpoint--md) {
        height: 25px;
      }
    }

    &_xl {
      margin-left: 2%;
      height: 45px;
      width: 93vw;
    }
    &_xl-height {
      margin-left: 2%;
      height: 25%;
      width: 93vw;
    }


    &_base-cold-dark {
      background-color: $base-cold-dark;
    }

    &_base-cold {
      background-color: $base-cold;
    }

    &_base-warm-light {
      background-color: $base-warm-light;
    }

    &_base-warm-dark {
      background-color: $base-warm-dark;
    }
  }

  .sm {
    margin-top: -30px;

    @media (min-width: $breakpoint--md) {
      margin-top: -45px;
    }
  }

  .xl {
    margin-top: -30px;

    @media (min-width: $breakpoint--md) {
      margin-top: -65px;
    }
  }

}
