@import "src/scss/config/config";

.beoordeling {
  margin: 20px auto;

  @media (min-width: $breakpoint--md) {
    margin: 20px auto;
    width: 200px;
  }

  &__content {
    @include font-bold;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: $base-cold-dark;
  }

  &__percentage {
    padding-top: 5px;
    color: $dark;
  }

  &__progress-bar {
    width: 65%;
    height: 10px;
    margin: 6px auto;
    border-radius: 5px;
    background-color: $base-cold-dark;

    @media (min-width: $breakpoint--md) {
      width: 90%;
      margin: 6px 15px;
    }
  }

  &__progress {
    height: 10px;
    display: block;
    border-radius: 5px;
    background-color: $base-warm-light;

  }
}

