@import "src/scss/config/config";

h1 {
  @include font-bold;
  margin: 0;
  font-size: 75px;

  -webkit-text-stroke-width: 1px;

  @media (min-width: $breakpoint--lg) {
    font-size: 224px;
    -webkit-text-stroke-width: 3px;
  }
}

h2 {
  @include font-bold;
  color: $dark;
  font-size: 56px;
}

h3 {
  @include font-bold;
  color: $dark;
  font-size: 18px;

  @media (min-width: $breakpoint--md) {
    font-size: 28px;
  }
}

h4 {
  @include font-bold;
  line-height: 12px;
  margin: 10px 0;
}


p {
  margin: 0;
  @include font-regular;
  font-size: 14px;
  line-height: 24px;
  color: $dark;
}

.typo {
  &__home {
    @include font-bold;
    font-size: 45px;
    letter-spacing: 5px;

    &-red{
      color: #27625a;
    }
    &-blue{
      color: $base-cold-dark;
    }
  }
}
