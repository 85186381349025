@import "src/scss/config/config";

html {
  @include font-regular;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  box-sizing: border-box;
  padding: 25px;
  max-width: 100vw;
  //padding: 50px;

  @media (min-width: $breakpoint--xl-extra) {
    box-sizing: border-box;
  }

  &_bg {
    &-light {
      background-color: $base-cold-light;
    }

    &-warm-light {
      background-color: $base-warm-light;
    }
  }

  @media (min-width: $breakpoint--md) {
    padding: 100px 70px;
  }
}

ul {
  list-style: initial;
  padding: 5px 15px;
  margin: 5px;
}

.btn {
  background-color: $base-cold;
  color: $dark;
  padding: 15px;
  border-radius: 15px;

  &:hover {
    background-color: $base-warm-light;
  }
}

