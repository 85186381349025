@import "src/scss/config/config";

.wie {
  &__info {
    margin: 15px;

    li {
      @include font-bold;
      font-size: 17px;
    }
  }

  &__img {
    width: auto;
    background-color: $lightest;
    margin: 15px;
    //height: 75px;

    &-logos {
      padding: 15px;
      background-color: $base-cold;
      width: 150px;
    }

    &-logos:hover {
      padding: 15px;
      background-color: $base-cold-dark;
      width: 150px;
    }
  }

  &__content {
    p {
      line-height: 30px;
    }
  }

  ul {
    list-style: none;
  }

  .link{
    a {
      color: $lightest;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
        color: $base-cold-dark;
      }
    }
  }

}
