@import "src/scss/config/config";

.hobby {
  &__quote {
    @include font-bold;
    line-height: 35px;
    text-align: center;
    font-size: 20px;
    color: $dark;
  }
}
