@import "src/scss/config/config";

.header {
  box-sizing: border-box;
  height: 50vh;

  @media (min-width: $breakpoint--md) {
    height: 75vh;
  }

  &__content {
    box-sizing: border-box;

    h1 {
      color: $lightest;
    }
  }

  &-sm{
    font-size: 55px;
    margin-top: -100px;

    @media (min-width: $breakpoint--md) {
      margin-top: -200px;
      font-size: 125px;
    }
  }
  &-xl{
    margin-top: -100px;

    @media (min-width: $breakpoint--md) {
      margin-top: -150px;
    }
    @media (min-width: $breakpoint--lg) {
      margin-top: -300px;
    }
  }

  &_bg-color {
    &_warm-dark {
      background-color: $base-warm-dark;
    }

    &_cold-dark {
      background-color: $base-cold-dark;
    }
  }

  .line {
    width: 30%;
    height: 50px;
    margin-left: 47%;
    margin-top: 150px;

    &_base_warm_light {
      background-color: $base-warm-light;
    }

    &_base-cold {
      background-color: $base-cold;
    }
    @media (min-width: $breakpoint--md) {
      height: 100px;
    }

  }
  h1 {
    text-align: center;
  }
}
