@import "src/scss/config/_config.scss";

.menu {
  padding: 5% 2%;

  h5 {
    @include font-regular;
    padding: 5px 15px;
  }

  &_active {
    background-color: $base-cold;
  }
}
