@import "src/scss/config/config";

.card {
  margin: 35px auto;
  background-color: $lightest;
  box-shadow: 0 20px 20px 0 rgba(121, 120, 129, 0.2);
  border-radius: 15px;

  &__photo {
    border-radius: 15px;
    overflow: hidden;
    position: relative;
    @include font-bold;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &_lg {
      width: 260px;
      height: 218px;
      @media (min-width: $breakpoint--md) {
        width: 350px;
        height: 300px;
      }

    }

    &_sqr {
      width: 250px;
      height: 250px;

      @media (min-width: $breakpoint--md) {
        width: 350px;
        height: 350px;
      }
      @media (min-width: $breakpoint--lg) {
        width: 450px;
        height: 450px;
      }
    }

    &_sqr-header {
      background-size: contain;
      background-position: center;
      width: 250px;
      height: 250px;

      @media (min-width: $breakpoint--md) {
        background-size: contain;
        background-position: center;
        width: 550px;
        height: 550px;
      }

      &_cover {
        background-size: cover;
      }
    }

    &_md {
      width: 150px;
      height: 75px;
      @media (min-width: $breakpoint--md) {
        width: 150px;
        height: 75px;
      }
    }
  }

  &_lg {
    position: relative;
    box-sizing: border-box;
    bottom: 15px;
    text-align: center;
    width: 260px;
    height: 218px;

    @media (min-width: $breakpoint--md) {
      width: 350px;
      height: 300px;
    }

    h3 {
      position: absolute;
      bottom: 10px;
    }

    img {
      margin-top: 15px;
      width: 75%;
    }
  }

  &_md {
    width: 150px;
    height: 75px;
    @media (min-width: $breakpoint--md) {
      width: 150px;
      height: 75px;
    }

    p {
      margin: auto;
    }
  }

  &_sqr-header {
    margin-top: -5px;
    width: 250px;
    height: 250px;

    @media (min-width: $breakpoint--md) {
      margin-top: -150px;
      width: 550px;
      height: 550px;
    }
  }

  &_sqr {
    width: 250px;
    height: 250px;

    @media (min-width: $breakpoint--md) {
      width: 350px;
      height: 350px;
    }
    @media (min-width: $breakpoint--lg) {
      width: 450px;
      height: 450px;
    }
  }

  &_hover_stijl {
    position: relative;
  }

  @media (min-width: $breakpoint--sm) {
    &_hover_stijl:hover {
      .hover_color_lg {
        z-index: 6;
        opacity: 0.75;
        position: absolute;
        width: 350px;
        height: 300px;
        background-color: $base-cold;
        border-radius: 15px;
      }

      .hover_color_sqr {
        z-index: 6;
        opacity: 0.75;
        position: absolute;
        width: 450px;
        height: 450px;
        background-color: $base-cold;
        border-radius: 15px;
      }

      .hover_text {
        z-index: 8;
        position: absolute;
        opacity: 1;

        &_lg {
          top: 90%;
          left: 10%;
        }

        &_sqr {
          top: 80%;
        }

        h3 {
          text-align: center;
          width: 300px;
        }

      }
    }
  }

  @media (max-width: $breakpoint--md) {
    &_hover_stijl {
      .hover_color_sqr {
        z-index: 6;
        opacity: 0.75;
        position: absolute;
        width: 250px;
        height: 250px;
        background-color: $base-cold-dark;
        border-radius: 15px;
      }
    }
  }

  .hover_text {
    position: absolute;
    opacity: 0;
    @media (max-width: $breakpoint--md) {
      z-index: 8;
      opacity: 1;
      &_sqr {
        top: 80%;
      }
    }
  }

  .img_hobby {
    //width: auto;
    border-radius: 15px;
    height: auto;
  }
}


